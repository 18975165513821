<template>
  <div>
    <v-btn small color="primary" @click="openDialog()">Export</v-btn>
    <v-dialog
      v-model="showDialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card v-if="showDialog" :loading="loading">
        <v-card-title class="overline">DB Übersetzung exportieren</v-card-title>
        <v-card-text>
          <span>Welche Sprachen?</span>
          <v-select
            v-model="selectedLanguages"
            solo
            flat
            dense
            :rules="rules.select"
            background-color="grey lighten-3"
            multiple
            :items="languages"
            item-text="id"
            :disabled="loading"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text :disabled="loading" @click="closeDialog()"
            >Schließen</v-btn
          >
          <v-btn small text @click="exportTranslations()" :loading="loading"
            >Export</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ExportDatabaseTranslationDialog",
  data() {
    return {
      showDialog: false,
      loading: false,
      selectedLanguages: [],
      rules: {
        text: [(v) => !!v || "Wert fehlt"],
        select: [(v) => (!!v && v.length > 0) || "Wert fehlt"],
      },
    };
  },
  computed: {
    ...mapGetters("app", { languages: "get_languages" }),
  },
  methods: {
    ...mapActions("translations", ["export_translations_db"]),
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async exportTranslations() {
      this.loading = true;
      var payload = {
        languages: [...this.selectedLanguages],
      };
      /* var config = {
        responseType: "blob",
      };
      console.log(config); */
      console.log(payload);

      var res = await this.export_translations_db(payload);
      this.loading = false;
      if (res) {
        var contentType = "text/plain";
        var blob = new Blob([res], { type: contentType });
        var filename = "test.txt";
        var link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
  },
};
</script>

<style></style>
