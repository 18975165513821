<template>
  <div>
    <v-container>
      <h1>Translations</h1>
      <v-row>
        <v-col cols="12" class="d-flex">
          <ExportDatabaseTranslationDialog />
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="my-8" />

    <v-container v-if="loading"> Lädt... </v-container>

    <v-container v-if="!loading">
      <v-row dense>
        <v-col cols="12" v-if="languages && languages.length > 0">
          <v-card outlined>
            <v-treeview
              v-if="tree && tree.data"
              :items="tree.data"
              item-key="id"
              item-text="id"
              item-children="children"
              :open.sync="open"
              loading
              open-on-click
            >
              <template v-slot:label="{ item }">
                <div class="d-flex">
                  <v-list-item dense class="mb-2">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.id }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.parent
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <div
                      v-if="!item.children || item.children.length === 0"
                      class="d-flex align-center pa-2"
                    >
                      <v-tooltip
                        v-for="(language, i) in options.selectedLanguages"
                        :key="i"
                        color="primary"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <FlagIcon
                              size="normal"
                              :lang="language.id"
                              class="mr-1"
                              :class="{
                                'flag-disabled': !languageAvailable(
                                  item,
                                  language
                                ),
                              }"
                            />
                          </div>
                        </template>
                        <div>
                          <div v-if="languageAvailable(item, language)">
                            <h5>Vorschau</h5>
                            <div>
                              <i>{{ languageText(item, language) }}</i>
                            </div>
                            <v-divider class="white my-2" />
                            <div class="d-flex align-center">
                              <v-icon class="mr-2" color="white">{{
                                languageVerified(item, language)
                                  ? "mdi-check-circle"
                                  : "mdi-alert-circle"
                              }}</v-icon>
                              <span class="font-weight-bold">
                                {{
                                  languageVerified(item, language)
                                    ? "Übersetzung geprüft"
                                    : "Prüfung ausstehend"
                                }}
                              </span>
                            </div>
                          </div>
                          <div v-if="!languageAvailable(item, language)">
                            Sprache fehlt
                          </div>
                        </div>
                      </v-tooltip>
                    </div>
                  </v-list-item>
                </div>
              </template>
              <template v-slot:prepend="{ item }">
                <v-icon>{{
                  !!item.children && item.children.length > 0
                    ? "mdi-folder-network"
                    : "mdi-translate"
                }}</v-icon>
              </template>
              <template v-slot:append="{ item }">
                <div
                  v-if="!item.children || item.children.length === 0"
                  class="d-flex align-center ml-4"
                >
                  <TranslationDbDialog :translation="item" class="ml-4" />
                </div>
              </template>
            </v-treeview>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ExportDatabaseTranslationDialog from "@/components/translations/ExportDatabaseTranslationDialog";
import TranslationDbDialog from "@/components/translations/TranslationDbDialog";
import FlagIcon from "@/components/misc/FlagIcon";

export default {
  name: "TranslationsDatabase",
  components: {
    ExportDatabaseTranslationDialog,
    TranslationDbDialog,
    FlagIcon,
  },
  data() {
    return {
      loading: false,
      open: [],
      options: {
        search: null,
        selectedLanguages: [],
        verified: false,
      },
    };
  },
  computed: {
    ...mapGetters("app", { languages: "get_languages" }),
    ...mapGetters("translations", { tree: "get_translation_db_tree" }),
  },
  beforeMount() {
    this.fetchData();
  },
  destroyed() {
    this.set_translation_db_tree(null);
  },
  methods: {
    ...mapActions("translations", ["fetch_translations_db"]),
    ...mapMutations("translations", ["set_translation_db_tree"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_translations_db();
      this.options.selectedLanguages = [...this.languages];
      this.loading = false;
    },
    languageAvailable(item, language) {
      let lang = item.languages.filter((l) => l.language_id === language.id)[0];
      return !!lang;
    },
    languageVerified(item, language) {
      let lang = item.languages.filter((l) => l.language_id === language.id)[0];
      return lang.is_verified;
    },
    languageText(item, language) {
      let lang = item.languages.filter((l) => l.language_id === language.id)[0];
      return lang.value;
    },
  },
};
</script>

<style></style>
