<template>
  <div>
    <v-btn x-small color="primary" @click="openDialog()">
      <v-icon small>mdi-pencil</v-icon>
    </v-btn>

    <v-dialog
      v-model="showDialog"
      max-width="640"
      scrollable
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card v-if="showDialog" :loading="loading">
        <v-card-title class="overline">Übersetzung bearbeiten</v-card-title>
        <v-card-text v-if="input">
          <v-list dense>
            <!-- <v-subheader>KEY</v-subheader> -->
            <v-list-item class="grey lighten-3 mb-4">
              <v-list-item-action>
                <v-btn icon small @click="copyId()">
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                {{ translation.complete_id }}
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-subheader>HAUPTSPRACHEN</v-subheader>
            <v-list-item
              v-for="(language, i) in mainLanguages"
              :key="`main-${i}`"
            >
              <v-list-item-icon
                ><FlagIcon :lang="language.id"
              /></v-list-item-icon>
              <v-list-item-content>
                <div>
                  <v-textarea
                    v-model="input[language.id].value"
                    solo
                    flat
                    dense
                    height="80"
                    hide-details
                    background-color="grey lighten-3"
                  />
                </div>
                <div>
                  <v-checkbox
                    v-model="options.translateAll[language.id]"
                    dense
                    :disabled="!inputHasChanged(language.id)"
                  >
                    <template #label>
                      <span class="caption"
                        >Andere Sprachen mitübersetzen"</span
                      >
                    </template>
                  </v-checkbox>
                </div>
                <!-- {{ input[language] }} :disabled="!inputChanged[language.id]" -->
              </v-list-item-content>
              <v-list-item-action class="align-self-start">
                <v-btn
                  small
                  icon
                  :disabled="!inputHasChanged(language.id) || loading"
                  @click="saveTranslation(language.id)"
                >
                  <v-icon small>mdi-content-save</v-icon>
                </v-btn>
                <v-btn
                  small
                  icon
                  :color="
                    input[language.id].is_verified ? 'success' : 'warning'
                  "
                  :disabled="inputHasChanged(language.id) || loading"
                  @click="verifyTranslation(language.id)"
                >
                  <v-icon small>{{
                    input[language.id].is_verified
                      ? "mdi-check-circle"
                      : "mdi-alert-circle"
                  }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider />

            <v-subheader>WEITERE SPRACHEN</v-subheader>
            <v-list-item
              v-for="(language, i) in otherLanguages"
              :key="`other-${i}`"
            >
              <v-list-item-icon
                ><FlagIcon :lang="language.id"
              /></v-list-item-icon>
              <v-list-item-content>
                <v-textarea
                  v-model="input[language.id].value"
                  solo
                  flat
                  dense
                  hide-details
                  height="80"
                  background-color="grey lighten-3"
                />
              </v-list-item-content>
              <v-list-item-action class="align-self-start">
                <v-btn
                  icon
                  small
                  :disabled="!inputHasChanged(language.id) || loading"
                  @click="saveTranslation(language.id)"
                >
                  <v-icon small>mdi-content-save</v-icon>
                </v-btn>
                <v-btn
                  small
                  icon
                  :color="
                    input[language.id].is_verified ? 'success' : 'warning'
                  "
                  :disabled="inputHasChanged(language.id) || loading"
                  @click="verifyTranslation(language.id)"
                >
                  <v-icon small>{{
                    input[language.id].is_verified
                      ? "mdi-check-circle"
                      : "mdi-alert-circle"
                  }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text :disabled="loading" @click="closeDialog()"
            >Schließen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FlagIcon from "@/components/misc/FlagIcon";

export default {
  name: "TranslationDialog",
  components: {
    FlagIcon,
  },
  props: {
    translation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      input: {},
      options: {
        translateAll: {},
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("app", { languages: "get_languages" }),
    mainLanguages() {
      if (!this.languages) return [];
      return this.languages.filter((l) => l.is_main);
    },
    otherLanguages() {
      if (!this.languages) return [];
      return this.languages.filter((l) => !l.is_main);
    },
  },
  watch: {
    translation() {
      this.initInput();
    },
  },
  methods: {
    ...mapActions("translations", ["update_translation_fe"]),
    openDialog() {
      this.initInput();
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    initInput() {
      console.log("init");
      //let input = [];
      console.log(this.translation);
      this.languages.forEach((l) => {
        let translation = this.translation.languages.filter(
          (t) => t.language_id === l.id
        )[0];
        this.$set(this.input, l.id, { ...translation });
      });
      console.log(this.languages.filter((l) => l.is_main));
      this.languages
        .filter((l) => l.is_main)
        .forEach((l) => {
          console.log(l);
          this.$set(this.options.translateAll, l.id, false);
        });
      //this.input = input;
      //this.$set(this.input, "", input);
      //console.log(this.input);
    },
    inputHasChanged(lang) {
      var translation = this.translation.languages.filter(
        (t) => t.language_id === lang
      )[0];
      var input = this.input[lang];
      return translation.value !== input.value;
    },
    async copyId() {
      try {
        await navigator.clipboard.writeText(this.translation.complete_id);
      } catch (e) {
        console.error(e);
      }
    },
    async saveTranslation(lang) {
      this.loading = true;
      var input = this.input[lang];
      var language = this.languages.filter((l) => l.id === lang)[0];
      var translateOthers = false;
      if (language.is_main) {
        translateOthers = this.options.translateAll[lang];
      }

      var payload = {
        id: this.translation.complete_id,
        value: input.value,
        is_verified: true,
        language_id: lang,
        translate: translateOthers,
      };

      await this.update_translation_fe(payload);
      this.loading = false;
    },
    async verifyTranslation(lang) {
      this.loading = true;
      var input = this.input[lang];
      //var language = this.languages.filter((l) => l.id === lang)[0];
      console.log(input);

      var payload = {
        id: this.translation.complete_id,
        value: input.value,
        is_verified: !input.is_verified,
        language_id: lang,
        translate: false,
      };
      console.log(payload);

      await this.update_translation_fe(payload);
      this.loading = false;
    },
  },
};
</script>

<style></style>
