import api from "@/plugins/api.js";

export const utils_translate = async ({ commit }, payload) => {
  try {
    console.log(payload);
    let [res] = await api.post(`/api/utils/translate`, payload);
    if (res && res._status === 200) {
      return res;
    }
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};

export const gen_salt = async ({ commit }, payload) => {
  try {
    console.log(payload);
    let [res] = await api.post(`/api/utils/salt`, payload);
    if (res && res._status === 200) {
      return res;
    }
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};

export const gen_gw = async ({ commit }, payload) => {
  try {
    console.log(payload);
    let [res] = await api.post(`/api/utils/ghostwriter`, payload);
    if (res && res._status === 200) {
      return res;
    }
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};
