<template>
  <div v-if="user">
    <v-navigation-drawer
      app
      color="secondary"
      dark
      permanent
      :expand-on-hover="true"
      :mini-variant="true"
      key="expanded"
    >
      <v-list dense>
        <v-list-item link :to="{ name: 'Start' }" exact>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Start</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-subheader>TRANSLATIONS</v-subheader>
        <v-list-item link :to="{ name: 'TranslationsFrontend' }">
          <v-list-item-icon>
            <v-icon>mdi-devices</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Frontend</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'TranslationsDatabase' }">
          <v-list-item-icon>
            <v-icon>mdi-database</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Datenbank</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list nav dense>
        <v-subheader>UTILS</v-subheader>
        <v-list-item link :to="{ name: 'UtilsTranslate' }">
          <v-list-item-icon>
            <v-icon>mdi-translate</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Übersetzer</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list nav dense>
        <v-list-item link :to="{ name: 'UtilsSecurity' }">
          <v-list-item-icon>
            <v-icon>mdi-security</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sicherheit</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider />

      <template v-slot:append>
        <v-list nav dense>
          <!-- 
        <v-btn block @click="logout()">
          <v-icon>mdi-logout</v-icon>
          <span v-if="nav.expanded">Logout</span>
        </v-btn> -->
          <v-list-item link :href="'https://suite.p3l.io/admin/'">
            <v-list-item-icon>
              <v-icon>mdi-account-heart</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout()" exact>
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SideNavigation",
  /* data() {
    return {
      expanded: true,
    };
  }, */
  computed: {
    ...mapGetters("auth", { user: "get_user" }),
    ...mapGetters("app", { nav: "get_side_nav_settings" }),
  },
  methods: {
    ...mapActions("auth", ["user_logout"]),
    async logout() {
      let res = await this.user_logout();
      if (res) this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style></style>
