<template>
  <div>
    <v-select
      :items="languages"
      v-model="selectedLanguages"
      :multiple="multiple"
      small-chips
      solo
      flat
      item-key="id"
      dense
      hide-details
      background-color="grey lighten-3"
    >
      <template #item="{ item }">
        <v-list-item
          @click="
            selectedLanguages.includes(item)
              ? removeLanguage(item)
              : setLanguage(item)
          "
        >
          <v-list-item-icon color="grey" class="d-flex align-center">
            <FlagIcon :lang="item.id" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.id }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-simple-checkbox
              readonly
              :value="!!selectedLanguages.includes(item)"
              @click="
                selectedLanguages.includes(item)
                  ? removeLanguage(item)
                  : setLanguage(item)
              "
            />
          </v-list-item-action>
        </v-list-item>
        <!-- <v-avatar size="24">
          <FlagIcon :lang="item.id" />
        </v-avatar>
        <span class="ml-2">{{ item.id }}</span> -->
      </template>
      <template #selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <v-avatar left>
            <FlagIcon :lang="item.id" />
          </v-avatar>
          <span>{{ item.id }}</span>
          <v-avatar right>
            <v-btn small icon @click.stop="removeLanguage(item.id)">
              <v-icon small color="error">mdi-close</v-icon>
            </v-btn>
          </v-avatar>
        </v-chip>
        <v-chip small color="primary lighten-1" v-if="index === 2">
          + {{ selectedLanguages.length }} weitere</v-chip
        >
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlagIcon from "@/components/misc/FlagIcon";

export default {
  name: "LanguageSwitcher",
  components: {
    FlagIcon,
  },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    app: {
      // TODO: move !multiple select to new component
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedLanguages: [],
      //loading: false,
    };
  },
  computed: {
    ...mapGetters("app", { languages: "get_languages" }),
    /* languages() {
      return this.$i18n.availableLocales;
    }, */
  },
  watch: {
    languages() {
      //console.log("jo");
      this.setAllLanguages();
    },
  },
  beforeMount() {
    this.setAllLanguages();
  },
  methods: {
    //...mapActions("app", ["fetch_languages"]),
    /* async fetchData() {
      this.loading = true;
      //await this.fetch_languages();
      this.setAllLanguages();
      this.loading = false;
    }, */
    setLanguage(e) {
      this.selectedLanguages.push(e);
      /* console.log("app", this.app);
      console.log("app", this.selectedLanguages); */
      // TODO: if(this.app) set app lang
      this.emit();
    },
    removeLanguage(lang) {
      this.selectedLanguages = this.multiple
        ? this.selectedLanguages.filter((l) => l !== lang)
        : null;
      this.emit();
    },
    emit() {
      //console.log("emit");
      this.$emit(
        "change",
        this.multiple ? this.selectedLanguages : [this.selectedLanguages]
      );
    },
    setAllLanguages() {
      // TODO: move to parent
      if (!this.languages) return false;
      let selectLanguages = [];
      this.languages.forEach((l) => selectLanguages.push(l));
      this.selectedLanguages = selectLanguages;
      this.emit();
    },
  },
};
</script>

<style></style>
