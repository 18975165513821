import api from "@/plugins/api.js";

export const fetch_languages = async ({ commit }) => {
  try {
    let [res] = await api.get(`/api/languages/`);
    commit("set_languages", res.languages);
  } catch (err) {
    console.log(err);
    return null;
  }
};
