import api from "@/plugins/api.js";
import { TranslationTree } from "@/models/TranslationTree.js";

export const fetch_translations_fe = async ({ commit }) => {
  try {
    let [res] = await api.get(`/api/translations/fe`);
    if (res && res._status === 200) {
      commit("set_translation_fe_tree", new TranslationTree(res.translations));
      return res;
    }
  } catch (e) {
    console.error(e);
  }
};
export const fetch_translations_db = async ({ commit }) => {
  try {
    let [res] = await api.get(`/api/translations/db`);
    if (res && res._status === 200) {
      commit("set_translation_db_tree", new TranslationTree(res.translations));
      return res;
    }
  } catch (e) {
    console.error(e);
  }
};

export const add_translation_fe = async ({ commit, dispatch }, payload) => {
  try {
    let [res] = await api.post(`/api/translations/fe`, payload);
    if (res && res._status === 200) {
      await dispatch("fetch_translations_fe");
      commit(
        "app/set_alert",
        { type: "success", text: "addedcode" },
        { root: true }
      );
      return res;
    }
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};

export const update_translation_fe = async ({ commit, dispatch }, payload) => {
  try {
    let [res] = await api.put(`/api/translations/fe/${payload.id}`, payload);
    if (res && res._status === 200) {
      await dispatch("fetch_translations_fe");
      commit(
        "app/set_alert",
        { type: "success", text: "updatecode" },
        { root: true }
      );
      return res;
    }
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};

export const delete_translation_fe = async ({ commit, dispatch }, payload) => {
  try {
    let [res] = await api.delete(`/api/translations/fe/${payload.id}`);
    if (res && res._status === 200) {
      await dispatch("fetch_translations_fe");
      commit(
        "app/set_alert",
        { type: "success", text: "deletecode" },
        { root: true }
      );
      return res;
    }
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};

export const export_translations_fe = async ({ commit }, { config }) => {
  try {
    let [res] = await api.downloadZIP(
      `/api/translations/export/fe`,
      {},
      config
    );
    return res;
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};

export const export_translations_db = async ({ commit }, payload) => {
  try {
    let [res] = await api.downloadTXT(`/api/translations/export/db`, payload);
    return res;
  } catch (e) {
    console.error(e);
    commit(
      "app/set_alert",
      { type: "error", text: "errorcode" },
      { root: true }
    );
  }
};
