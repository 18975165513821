import Vue from "vue";
import Vuex from "vuex";

import app from "@/store/modules/app/module";
import auth from "@/store/modules/auth/module";
import translations from "@/store/modules/translations/module";
import utils from "@/store/modules/utils/module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    translations,
    utils,
  },
});
