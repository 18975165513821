<template>
  <div>
    <v-container>
      <h1>Übersetzer</h1>
      <v-row>
        <v-col cols="12">
          <v-card outlined :loading="loading">
            <v-form v-model="valid">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <span>Eingabe (Text o. HTML)</span>
                    <v-textarea
                      v-model="input.text"
                      min-height="120"
                      flat
                      solo
                      :rules="rules.text"
                      background-color="grey lighten-3"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span>Ausgangssprache</span>
                    <v-select
                      v-model="input.language"
                      solo
                      flat
                      dense
                      :rules="rules.select"
                      background-color="grey lighten-3"
                      :items="languages"
                      item-text="id"
                      :disabled="!!translations"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span>Zielsprache(n)</span>
                    <v-select
                      v-model="input.languages"
                      solo
                      flat
                      dense
                      :rules="rules.select"
                      background-color="grey lighten-3"
                      multiple
                      :items="languages"
                      item-text="id"
                      :disabled="!!translations"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span>Symbole ausschließen</span>
                    <v-select
                      v-model="input.options.protect"
                      solo
                      flat
                      dense
                      background-color="grey lighten-3"
                      multiple
                      :items="options.protect"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  small
                  color="secondary"
                  :disabled="!input.text"
                  @click="showPreview = !showPreview"
                >
                  <v-icon class="mr-2" small>{{
                    showPreview ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                  <span>Vorschau</span>
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  small
                  color="error"
                  :disabled="loading"
                  @click="reset()"
                  >Zurücksetzen</v-btn
                >
                <v-btn
                  text
                  small
                  :disabled="!valid"
                  :loading="loading"
                  @click="translate()"
                  >Übersetzen</v-btn
                >
              </v-card-actions>
              <v-expand-transition>
                <v-card-text v-show="showPreview">
                  <v-sheet outlined class="pa-2">
                    <div v-html="input.text" />
                  </v-sheet>
                </v-card-text>
              </v-expand-transition>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-text v-if="!translations">
              Keine Ausgabe vorhanden.
            </v-card-text>
            <v-card-text v-if="translations">
              <v-row>
                <v-col cols="12">
                  <v-btn-toggle v-model="activeTranslation">
                    <v-btn
                      v-for="(translation, i) in translations"
                      :key="`btn-${i}`"
                      >{{ translation.id }}</v-btn
                    >
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12">
                  <span>Ausgabe</span>
                  <v-textarea
                    v-model="translations[activeTranslation].text"
                    height="120"
                    flat
                    solo
                    background-color="grey lighten-3"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="translations">
              <v-btn
                text
                small
                color="secondary"
                :disabled="!translations[activeTranslation].text"
                @click="showResponsePreview = !showResponsePreview"
              >
                <v-icon class="mr-2" small>{{
                  showResponsePreview ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                <span>Vorschau</span>
              </v-btn>
              <v-spacer />
              <v-btn text small @click="copyResponse()">Inhalt kopieren</v-btn>
            </v-card-actions>
            <v-expand-transition v-if="translations">
              <v-card-text v-show="showResponsePreview">
                <v-sheet outlined class="pa-2">
                  <div v-html="translations[activeTranslation].text" />
                </v-sheet>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TranslationPreview",
  data() {
    return {
      input: {
        language: "de-DE",
        languages: [],
        text: "",
        options: {
          protect: ["brackets"],
        },
      },
      rules: {
        text: [(v) => !!v || "Wert fehlt"],
        select: [(v) => (!!v && v.length > 0) || "Wert fehlt"],
      },
      options: {
        protect: ["brackets", "curly"],
        //templates: email -> brackets, json ->
      },
      showPreview: false,
      showResponsePreview: false,
      valid: false,
      translations: null,
      activeTranslation: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("app", { languages: "get_languages" }),
  },
  methods: {
    ...mapActions("utils", ["utils_translate"]),
    async translate() {
      this.loading = true;
      var payload = {
        language: this.input.language,
        languages: [...this.input.languages],
        text: this.input.text,
        protect: [...this.input.options.protect],
      };

      var res = await this.utils_translate(payload);
      this.loading = false;
      this.translations = res.languages;
    },
    reset() {
      this.input = {
        language: "de-DE",
        languages: [],
        text: "",
        options: {
          protect: ["brackets"],
        },
      };
      this.translations = null;
    },
    async copyResponse() {
      try {
        await navigator.clipboard.writeText(
          this.translations[this.activeTranslation].text
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style></style>
