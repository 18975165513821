<template>
  <div>
    <v-container>
      <h1>Profile</h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ProfileView",
};
</script>

<style></style>
