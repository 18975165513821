<template>
  <div>
    <CountryFlag :country="getFlagName(lang)" :size="size" />
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "FlagIcon",
  components: {
    CountryFlag,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    size: {
      // small, normal, big
      type: String,
      required: false,
      default: "normal",
    },
  },
  methods: {
    getFlagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      return lang.slice(-2);
    },
  },
};
</script>

<style></style>
