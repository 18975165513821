<template>
  <div class="fill-height">
    <v-container class="fill-height">
      <v-row align="center">
        <v-col cols="10" offset="1" md="6" offset-md="3">
          <v-card flat color="grey lighten-3" class="pb-8 pt-4" shaped>
            <v-card-title class="overline justify-center">Login</v-card-title>
            <v-divider class="mb-4" />
            <v-card-text class="fill-height d-flex align-center justify-center">
              <v-form v-model="valid">
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      solo
                      flat
                      label="E-Mail"
                      :rules="[rules.email]"
                      :disabled="loading"
                      autocomplete="null"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      solo
                      flat
                      label="Password"
                      type="password"
                      :rules="[rules.password]"
                      :disabled="loading"
                      autocomplete="null"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="login()"
                      color="primary"
                      block
                      :disabled="!valid || loading"
                      :loading="loading"
                      >Login</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      :href="'https://suite.p3l.io/auth/login'"
                      color=""
                      depressed
                      small
                      block
                      target="_blank"
                      >auth</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      valid: false,
      loading: false,
      email: "",
      password: "",
      rules: {
        email: (v) =>
          (!!v && v.match(/\S+@\S+\.\S+/) != null) || "invalid mail",
        password: (v) => (!!v && v.length >= 1) || "Password needed",
      },
    };
  },
  computed: {
    ...mapGetters("auth", { user: "get_user" }),
  },
  watch: {
    user(v) {
      if (v) this.$router.push({ name: "Start" });
    },
  },
  methods: {
    ...mapActions("auth", ["user_login"]),
    async login() {
      console.log(this.email, this.password);
      this.loading = true;
      let payload = {
        email: this.email,
        password: this.password,
      };
      let res = await this.user_login({ payload: payload });
      console.log(res);
      //await new Promise((resolve) => setTimeout(resolve, 2000));
      /* if (res) {
        this.$router.push({ name: "Start" });
      } else {
        console.log("error");
      } */
    },
  },
};
</script>

<style></style>
