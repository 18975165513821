<template>
  <div>
    <v-btn x-small color="error" @click="openDialog()">
      <v-icon small>mdi-delete</v-icon>
    </v-btn>
    <v-dialog
      v-model="showDialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card v-if="showDialog" :loading="loading">
        <v-card-title class="overline">Übersetzung löschen</v-card-title>
        <v-card-text> Kann man doch nochmal gebrauchen... </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text @click="closeDialog()" :disabled="loading"
            >Abbrechen</v-btn
          >
          <v-btn
            small
            text
            color="error"
            @click="deleteTranslation()"
            :loading="loading"
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DeleteTranslationDialog",
  props: {
    translation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("translations", ["delete_translation_fe"]),
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async deleteTranslation() {
      this.loading = true;
      let res = await this.delete_translation_fe({
        id: this.translation.complete_id,
      });
      console.log(res);
      this.loading = false;
      if (res) this.closeDialog();
    },
  },
};
</script>

<style></style>
