<template>
  <v-app>
    <TopNavigation />
    <SideNavigation />

    <v-main>
      <router-view />
    </v-main>

    <notifications position="bottom left" class="alerts" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TopNavigation from "@/components/_layout/TopNavigation";
import SideNavigation from "@/components/_layout/SideNavigation";

export default {
  name: "App",
  components: { TopNavigation, SideNavigation },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters("app", { alert: "get_alert" }),
  },
  watch: {
    alert(v) {
      this.handleAlert(v);
    },
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    ...mapActions("auth", ["fetch_user"]),
    ...mapActions("app", ["fetch_languages"]),
    ...mapMutations("auth", ["set_user"]),
    ...mapMutations("app", ["set_alert"]),
    async fetchData() {
      //await this.fetch_user();
      await this.fetch_languages();
    },
    handleAlert(v) {
      if (v) {
        this.$notify({ type: v.type, text: v.text });
        this.set_alert(null);
      }
    },
  },
};
</script>
