<template>
  <div>
    <v-container>
      <h1>Start</h1>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <v-hover v-slot="{ hover }">
            <v-card
              color="primary"
              height="300"
              dark
              style="transition: 0.4s"
              :elevation="hover ? 8 : 2"
            >
              <v-img
                src="@/assets/flags.jpg"
                :max-height="hover ? 120 : 180"
                cover
                style="transition: 0.4s"
                class="d-flex align-end fill-height"
              >
              </v-img>
              <v-card-title>Translations</v-card-title>
              <v-card-text
                >Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                Lorem ipsum Lorem ipsum</v-card-text
              >
              <v-fade-transition>
                <v-card-actions v-show="hover">
                  <v-spacer />
                  <v-btn small text :to="{ name: 'TranslationsDatabase' }"
                    >Datenbank</v-btn
                  >
                  <v-btn small text :to="{ name: 'TranslationsFrontend' }"
                    >Frontend</v-btn
                  >
                </v-card-actions>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-hover v-slot="{ hover }">
            <v-card
              :to="{ name: 'UtilsTranslate' }"
              color="secondary"
              height="300"
              dark
              style="transition: 0.4s"
              :elevation="hover ? 8 : 2"
            >
              <v-img
                src="@/assets/translator.jpg"
                :max-height="hover ? 120 : 180"
                cover
                style="transition: 0.4s"
                class="d-flex align-end fill-height"
              />
              <v-card-title>Übersetzer</v-card-title>
              <v-card-text
                >Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                Lorem ipsum Lorem ipsum</v-card-text
              >
              <v-fade-transition>
                <v-card-actions v-show="hover">
                  <v-spacer />
                  <v-btn small text>Gehe zu</v-btn>
                </v-card-actions>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-hover v-slot="{ hover }">
            <v-card
              height="300"
              color="grey lighten-3"
              style="transition: 0.4s"
              :elevation="hover ? 8 : 2"
              disabled
            >
              <v-img
                src="@/assets/clients.jpg"
                :max-height="hover ? 120 : 180"
                cover
                style="transition: 0.4s"
                class="d-flex align-end fill-height"
              />
              <v-card-title>Instanzen</v-card-title>
              <v-card-text
                >Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                Lorem ipsum Lorem ipsum</v-card-text
              >
              <v-fade-transition>
                <v-card-actions v-show="hover">
                  <v-spacer />
                  <v-btn small text>Gehe zu</v-btn>
                </v-card-actions>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "StartView",
};
</script>

<style></style>
