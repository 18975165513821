import Vue from "vue";
import VueRouter from "vue-router";

// main
import StartView from "@/views/_main/StartView.vue";

// auth
import LoginView from "@/views/_auth/LoginView.vue";
import ProfileView from "@/views/_auth/ProfileView.vue";

// translations
import TranslationPreview from "@/views/utils/TranslationPreview.vue";
import SecurityUtils from "@/views/utils/SecurityUtils.vue";
import TranslationsFrontend from "@/views/translations/TranslationsFrontend.vue";
import TranslationsDatabase from "@/views/translations/TranslationsDatabase.vue";

import $store from "@/store";

Vue.use(VueRouter);

const checkAuth = async (to, from, next) => {
  var user = $store.getters["auth/get_user"];
  if (user) {
    return next();
  } else {
    await $store.dispatch("auth/fetch_user", null, { root: true });
    if ($store.getters["auth/get_user"]) {
      next();
    } else next("/login");
  }
};

const routes = [
  // main
  {
    path: "/",
    name: "Start",
    component: StartView,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
  // auth
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
  // translations
  {
    path: "/translations/fe",
    name: "TranslationsFrontend",
    component: TranslationsFrontend,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
  {
    path: "/translations/db",
    name: "TranslationsDatabase",
    component: TranslationsDatabase,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
  {
    path: "/utils/translate",
    name: "UtilsTranslate",
    component: TranslationPreview,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
  {
    path: "/utils/security",
    name: "UtilsSecurity",
    component: SecurityUtils,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
