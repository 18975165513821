<template>
  <div>
    <v-btn x-small color="primary" @click="openDialog()">
      <v-icon x-small class="mr-2">mdi-plus</v-icon>
      <span>Übersetzung hinzufügen</span>
    </v-btn>
    <v-dialog
      v-model="showDialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card v-if="showDialog" :loading="loading">
        <v-card-title class="overline">Übersetzung hinzufügen</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span>Ausgangssprache</span>
              <v-select
                v-model="input.language_id"
                :items="availableLanguages"
                item-value="id"
                item-text="id"
                label="lang"
                solo
                flat
                dense
                hide-details
                background-color="grey lighten-3"
              >
                <template #item="{ item }">
                  <FlagIcon :lang="item.id" />
                  <span class="ml-2">{{ item.id }}</span>
                </template>
                <template #selection="{ item }">
                  <FlagIcon :lang="item.id" />
                  <span class="ml-2">{{ item.id }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <span>ID (komplett)</span>
              <v-text-field
                v-model="input.id"
                label="id"
                dense
                hide-details
                solo
                flat
                background-color="grey lighten-3"
              />
            </v-col>
            <v-col cols="12">
              <span>Text</span>
              <v-text-field
                v-model="input.value"
                label="value"
                dense
                hide-details
                solo
                flat
                background-color="grey lighten-3"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text @click="closeDialog()" :disabled="loading"
            >Abbrechen</v-btn
          >
          <v-btn small text @click="addTranslation()" :loading="loading"
            >Hinzufügen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FlagIcon from "@/components/misc/FlagIcon";

export default {
  name: "AddTranslationDialog",
  components: {
    FlagIcon,
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      input: {
        language_id: "de-DE",
        value: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters("app", { languages: "get_languages" }),
    availableLanguages() {
      if (!this.languages) return [];
      return this.languages.filter((l) => l.is_main);
    },
  },
  methods: {
    ...mapActions("translations", ["add_translation_fe"]),
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.reset();
      this.showDialog = false;
    },
    async addTranslation() {
      this.loading = true;
      var payload = {
        id: this.input.id,
        language_id: this.input.language_id,
        value: this.input.value,
      };
      var res = await this.add_translation_fe(payload);
      this.loading = false;
      console.log(res);
      if (res && res._status === 200) {
        this.closeDialog();
      }
    },
    reset() {
      this.input = {
        language_id: "de-DE",
        value: "",
        id: "",
      };
    },
  },
};
</script>

<style></style>
