<template>
  <v-app-bar v-if="visible" app color="primary" dark dense>
    <v-spacer />
    <div class="mr-4">
      <v-list-item dense>
        <v-list-item-content class="text-right py-0">
          <v-list-item-title class="overline">P3L Suite</v-list-item-title>
          <v-list-item-subtitle class="caption">{{
            user.email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <router-link :to="{ name: 'Profile' }">
      <v-avatar size="32">
        <v-img :src="require('@/assets/avatar.png')" />
      </v-avatar>
    </router-link>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopNavigation",
  computed: {
    ...mapGetters("auth", { user: "get_user" }),
    visible() {
      return this.user !== null;
    },
  },
};
</script>

<style></style>
