<template>
  <div>
    <v-btn small color="primary" @click="openDialog()">Export</v-btn>
    <v-dialog
      v-model="showDialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card v-if="showDialog" :loading="loading">
        <v-card-title class="overline">FE Übersetzung exportieren</v-card-title>
        <v-card-text>
          <p>Text damit es nicht so leer aussieht</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text :disabled="loading" @click="closeDialog()"
            >Schließen</v-btn
          >
          <v-btn small text @click="exportTranslations()" :loading="loading"
            >Export</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ExportFrontendTranslationDialog",
  data() {
    return {
      showDialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("translations", ["export_translations_fe"]),
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async exportTranslations() {
      this.loading = true;
      var config = {
        responseType: "blob",
      };
      var res = await this.export_translations_fe(config, this.type);

      var contentType = "application/zip";
      var blob = new Blob([res], { type: contentType });
      var filename = "test.zip";
      this.loading = false;
      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
  },
};
</script>

<style></style>
