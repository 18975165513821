<template>
  <div>
    <v-container>
      <h1>Sicherheit</h1>
      <v-row>
        <v-col cols="6">
          <v-form>
            <v-card>
              <v-card-title>
                <v-icon left>mdi-key</v-icon>
                <span>Generate Salt</span>
              </v-card-title>
              <v-card-text>
                <v-select v-model="input.length" :items="saltConfigs" />
                <code v-if="salt">{{ salt }}</code>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="generate_salt()">
                  <span>Generate</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col cols="6">
          <v-form>
            <v-card>
              <v-card-title>
                <v-icon left>mdi-key</v-icon>
                <span>Ghostwriter Hashed</span>
              </v-card-title>
              <v-card-text>
                <p>Enter client password from 1password:</p>
                <v-text-field
                  v-model="input.password"
                  dense
                  solo
                  background-color="grey lighten-3"
                  hide-details
                  flat
                />
                <code v-if="ghostwriter">{{ ghostwriter }}</code>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="generate_ghostwriter()">
                  <span>Generate</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SecurityUtils",
  data() {
    return {
      input: {
        length: 24,
        password: "",
      },
      salt: null,
      ghostwriter: null,
      saltConfigs: [
        { text: "OAuth2 > Client ID", value: 24 },
        { text: "OAuth2 > Client Secret", value: 48 },
      ],
    };
  },
  methods: {
    ...mapActions("utils", ["gen_salt", "gen_gw"]),
    async generate_salt() {
      this.loading = true;
      var payload = { length: this.input.length };

      var res = await this.gen_salt(payload);
      this.loading = false;
      this.salt = res.salt;
    },
    async generate_ghostwriter() {
      this.loading = true;
      var payload = {
        password: this.input.password,
      };
      var res = await this.gen_gw(payload);
      this.ghostwriter = res.hashed;
      this.loading = false;
    },
    reset() {
      this.input = { length: 24 };
      this.salt = null;
    },
  },
};
</script>

<style></style>
