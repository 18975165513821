import api from "@/plugins/api.js";

export const user_login = async ({ commit, dispatch }, { payload }) => {
  try {
    let [res] = await api.post("/api/auth/login", payload);
    if (res._status === 200) {
      dispatch("fetch_user");
      return res;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    commit("set_user", null);
    return err;
  }
};

export const user_logout = async ({ commit }) => {
  try {
    let [res] = await api.post("/api/auth/logout", null);
    if (res._status === 200) {
      commit("set_user", null);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const fetch_user = async ({ commit }) => {
  try {
    let [res] = await api.get("/api/auth/user");
    if (!!res && res._status === 200) {
      commit("set_user", res.auth);
    } else {
      commit("set_user", null);
    }
  } catch (err) {
    commit("set_user", null);
    console.log(err);
    return err;
  }
};
