<template>
  <div>
    <v-container>
      <h1>Translations</h1>
      <v-row>
        <v-col cols="12" class="d-flex">
          <ExportFrontendTranslationDialog />
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="my-8" />

    <v-container v-if="loading"> Lädt... </v-container>

    <v-container v-if="!loading">
      <v-row>
        <v-col cols="12" sm="6">
          <v-sheet>
            <v-text-field
              v-model="options.search"
              label="Keys durchsuchen"
              flat
              solo
              dense
              background-color="grey lighten-3"
              hide-details
              :disabled="loading"
              class="black--text"
            >
              <template #prepend-inner>
                <v-icon color="black" class="mr-2">mdi-magnify</v-icon>
              </template>
              <template #append>
                <v-btn icon small @click="options.search = ''">
                  <v-icon v-if="options.search" color="black">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6">
          <LanguageSwitcher
            multiple
            app
            @change="setSelectedLanguages"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="options.searchTranslation"
            label="Übersetzungen durchsuchen"
            flat
            solo
            dense
            background-color="grey lighten-3"
            hide-details
            clearable
            :disabled="loading"
            class="black--text"
          >
            <template #prepend-inner>
              <v-icon color="black" class="mr-2">mdi-printer-search</v-icon>
            </template>
            <template #append-outer>
              <v-btn small icon @click="searchTranslation()">
                <v-icon color="black" class="mr-2">mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <v-expand-transition>
            <v-sheet
              v-show="!!translationSearchResults && !!options.searchTranslation"
              color="grey lighten-3"
            >
              <span
                v-if="
                  !translationSearchResults ||
                  translationSearchResults.length === 0
                "
                >Keine Ergebnisse zur Suche oder nicht auf Suchen BTN
                geklickt.</span
              >
              <div v-if="translationSearchResults.length > 0">
                <v-list class="transparent">
                  <v-list-item
                    v-for="(result, i) in translationSearchResults"
                    :key="i"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        result.complete_id
                      }}</v-list-item-title>
                      <v-list-item-subtitle>complete_id</v-list-item-subtitle>
                    </v-list-item-content>
                    <div
                      v-if="result.languages"
                      class="d-flex align-center pa-2"
                    >
                      <v-tooltip
                        v-for="(language, i) in result.languages"
                        :key="i"
                        color="primary"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <FlagIcon
                              size="normal"
                              :lang="language.language_id"
                              class="mr-1"
                            />
                          </div>
                        </template>
                        <div>
                          <h5>Vorschau</h5>
                          <div>
                            <i>{{ language.value }}</i>
                          </div>
                        </div>
                      </v-tooltip>
                    </div>
                    <v-list-item-action>
                      <TranslationDialog
                        :translation="result"
                        class="mr-2 ml-4"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </v-sheet>
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!loading">
      <v-row dense>
        <v-col cols="12" class="d-flex justify-space-between">
          <AddTranslationDialog />
          <v-btn
            x-small
            color="secondary"
            :disabled="open.length === 0"
            @click="closeAll()"
          >
            <v-icon x-small class="mr-2">mdi-minus</v-icon>
            <span>Alle schließen</span>
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="languages && languages.length > 0">
          <v-card outlined>
            <v-treeview
              v-if="tree && tree.data"
              :items="tree.data"
              item-key="id"
              item-text="id"
              item-children="children"
              :open.sync="open"
              :filter="filter"
              loading
              :search="options.search"
              open-on-click
            >
              <template v-slot:label="{ item }">
                <div class="d-flex">
                  <v-list-item dense class="mb-2">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.id }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.parent
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <div
                      v-if="!item.children || item.children.length === 0"
                      class="d-flex align-center pa-2"
                    >
                      <v-tooltip
                        v-for="(language, i) in options.selectedLanguages"
                        :key="i"
                        color="primary"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <FlagIcon
                              size="normal"
                              :lang="language.id"
                              class="mr-1"
                              :class="{
                                'flag-disabled': !languageAvailable(
                                  item,
                                  language
                                ),
                              }"
                            />
                          </div>
                        </template>
                        <div>
                          <div v-if="languageAvailable(item, language)">
                            <h5>Vorschau</h5>
                            <div>
                              <i>{{ languageText(item, language) }}</i>
                            </div>
                            <v-divider class="white my-2" />
                            <div class="d-flex align-center">
                              <v-icon class="mr-2" color="white">{{
                                languageVerified(item, language)
                                  ? "mdi-check-circle"
                                  : "mdi-alert-circle"
                              }}</v-icon>
                              <span class="font-weight-bold">
                                {{
                                  languageVerified(item, language)
                                    ? "Übersetzung geprüft"
                                    : "Prüfung ausstehend"
                                }}
                              </span>
                            </div>
                          </div>
                          <div v-if="!languageAvailable(item, language)">
                            Sprache fehlt
                          </div>
                        </div>
                      </v-tooltip>
                    </div>
                  </v-list-item>
                </div>
              </template>
              <template v-slot:prepend="{ item }">
                <v-icon>{{
                  !!item.children && item.children.length > 0
                    ? "mdi-folder-network"
                    : "mdi-translate"
                }}</v-icon>
              </template>
              <template v-slot:append="{ item }">
                <div
                  v-if="!item.children || item.children.length === 0"
                  class="d-flex align-center ml-4"
                >
                  <TranslationDialog :translation="item" class="mr-2 ml-4" />
                  <DeleteTranslationDialog :translation="item" />
                </div>
              </template>
            </v-treeview>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LanguageSwitcher from "@/components/misc/LanguageSwitcher";
import TranslationDialog from "@/components/translations/TranslationDialog";
import AddTranslationDialog from "@/components/translations/AddTranslationDialog";
import ExportFrontendTranslationDialog from "@/components/translations/ExportFrontendTranslationDialog";
import DeleteTranslationDialog from "@/components/translations/DeleteTranslationDialog";
import FlagIcon from "@/components/misc/FlagIcon";

export default {
  name: "TranslationsFrontend",
  components: {
    LanguageSwitcher,
    TranslationDialog,
    AddTranslationDialog,
    ExportFrontendTranslationDialog,
    DeleteTranslationDialog,
    FlagIcon,
  },
  data() {
    return {
      open: ["test"],
      options: {
        search: null,
        searchTranslation: null,
        selectedLanguages: [],
        verified: false,
      },
      loading: false,
      searchTranslationArr: [],
      translationSearchResults: [],
    };
  },
  computed: {
    ...mapGetters("app", { languages: "get_languages" }),
    ...mapGetters("translations", { tree: "get_translation_fe_tree" }),
    filter() {
      return (item, search, textKey) => {
        return item[textKey] ? item[textKey].indexOf(search) > -1 : undefined;
      };
    },
  },
  beforeMount() {
    this.fetchData();
  },
  destroyed() {
    this.set_translation_fe_tree(null);
  },
  watch: {
    tree: {
      handler: function () {
        if (this.tree) this.generateSearchTranslationArr();
      },
    },
  },
  methods: {
    ...mapActions("translations", ["fetch_translations_fe"]),
    ...mapMutations("translations", ["set_translation_fe_tree"]),
    setSelectedLanguages(e) {
      this.options.selectedLanguages = e;
    },
    async fetchData() {
      this.loading = true;
      await this.fetch_translations_fe();
      this.loading = false;
    },
    closeAll() {
      this.open = [];
    },
    languageAvailable(item, language) {
      let lang = item.languages.filter((l) => l.language_id === language.id)[0];
      return !!lang;
    },
    languageVerified(item, language) {
      let lang = item.languages.filter((l) => l.language_id === language.id)[0];
      return lang.is_verified;
    },
    languageText(item, language) {
      let lang = item.languages.filter((l) => l.language_id === language.id)[0];
      return lang.value;
    },
    propertiesToArray(obj) {
      const isObject = (val) =>
        val && typeof val === "object" && !Array.isArray(val);

      const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

      const paths = (obj = {}, head = "") => {
        return Object.entries(obj).reduce((product, [key, value]) => {
          let fullPath = addDelimiter(head, key);
          return isObject(value)
            ? product.concat(paths(value, fullPath))
            : product.concat(value); //product.concat(fullPath);
        }, []);
      };

      return paths(obj);
    },
    generateSearchTranslationArr() {
      this.loading = true;
      var data = [...this.tree.data];
      data.forEach((d) => {
        this.getNodeChildren(d);
      });
      this.loading = false;
    },
    getNodeChildren(obj) {
      if (!obj.children || obj.children.length === 0) {
        this.searchTranslationArr.push(obj);
        return;
      }
      obj.children.forEach((c) => this.getNodeChildren(c));
    },
    searchTranslation() {
      var search = this.options.searchTranslation;
      var results = [];
      if (!search || search.length === 0) return [];
      // fuzzy search
      this.searchTranslationArr.forEach((obj) => {
        obj.languages.forEach((l) => {
          var alreadyfound =
            results.filter((r) => r.complete_id === obj.complete_id).length > 0;
          if (l.value.toLowerCase() === search.toLowerCase() && !alreadyfound)
            results.push(obj);
        });
      });
      this.translationSearchResults = results;
    },
  },
};
</script>

<style>
.flag-disabled {
  opacity: 0.2;
}
</style>
