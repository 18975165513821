export class TranslationTree {
  constructor(obj) {
    this.data = obj ? this.generateTree(obj) : [];
  }

  generateTree(obj) {
    var tree = [];
    var keys = Object.keys(obj);

    keys.forEach((key) => {
      var data = obj[key];
      if (Object.keys(data).length === 0) return false;
      var layer = {
        id: key,
        //children: [],
      };
      var layer_is_translation = this.checkLayerIsTranslation(data);
      if (layer_is_translation) {
        layer.children = [this.generateTranslationLayer(data)];
      } else {
        layer.children = this.generateLayer(data);
      }
      tree.push(layer);
    });
    return tree;
  }

  checkLayerIsTranslation(layer) {
    return layer.constructor === Array;
  }
  generateLayer(obj) {
    var keys = Object.keys(obj);
    var list = [];
    keys.forEach((key) => {
      var data = obj[key];
      //console.log(data);
      var layer = {
        //id: data[0] ? (data[0].id ? data[0].id : key) : data[0],
        id: key,
        complete_id: data.id
          ? data.id
          : data[0] && data[0].id
          ? data[0].id
          : "?",
        children: [],
      };
      //console.log(layer);
      var layer_is_translation = this.checkLayerIsTranslation(data);
      if (layer_is_translation) {
        layer.languages = this.generateTranslationLayer(data).languages;
        delete layer.children;
      } else {
        layer.children = this.generateLayer(data);
      }
      list.push(layer);
    });
    return list;
  }

  generateTranslationLayer(data) {
    //console.log(data);
    //var layer = { id: data.id, short_id: data[0].id };
    var layer = { id: data[0].id };
    var entries = [];
    data.forEach((translation) => {
      entries.push({
        language_id: translation.language_id,
        is_verified: translation.is_verified,
        value: translation.value, // not needed in overview
      });
    });
    layer.languages = entries;
    return layer;
  }
}
